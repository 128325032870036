import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

const NSWInformation = ({ path }) => {
  const tables = {
    packages: [
      {
        description: "Cremation",
        price: 515,
      },
      {
        description: "Doctors Cremation Certificate",
        price: 110,
      },
      {
        description: "Doctors Referee",
        price: 110,
      },
      {
        description: "Return Registered Post of ashes",
        price: 55,
      },
      {
        description: "Basic Cremation coffin",
        price: 135,
      },
      {
        description: "Death certificate",
        price: 65,
      },
      {
        description: "Professional fees",
        price: 1210,
      },
    ],
    transfers: {
      weekdays: [
        {
          description: "Local – Greater Sydney Metro",
          price: 250,
        },
        {
          description: "Regional within 100km of IMS",
          price: 395,
        },
      ],
      weekends: [
        {
          description: "Local – Greater Sydney Metro",
          price: 250,
        },
        {
          description: "Regional within 100km of IMS",
          price: 395,
        },
      ],
    },
  }

  return (
    <Layout loadMap={false}>
      <SEO
        path={path}
        title="Information - EstatesPlus - Wills, Cremation, Probate & Letters of Administration. Save 80% on Legal Fees and Cremations "
        description="This is a breakdwown pricing for NSW cremation"
        image="/img/signing-during-covid-19-victoria-copy-1024x683.jpeg"
      />
      <section className="bg-white section-gap">
        <div className="container">
          <h2 className="mb-3">NSW Information</h2>
          <p className="mb-5">
            Here is a breakdown of our fees and charges in NSW for our cremation
            services.
          </p>
          {/* Packages */}
          <div className="row mb-4">
            <div className="col-12 col-lg-8">
              <h3 className="font-bold mb-4">Our Basic Funeral Packages</h3>
              <table class="table custom-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <p className="mb-0">
                        Collection and storage of body for 48 hours
                      </p>
                    </th>
                    <th scope="col" aria-label="prices"></th>
                  </tr>
                </thead>
                <tbody>
                  {tables.packages.map((item, i) => {
                    return (
                      <tr key={`table-packages-${i}`}>
                        <td>
                          <p className="mb-0">{item.description}</p>
                        </td>
                        <td>
                          <p className="mb-0">${item.price}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* Transfers */}
          <div className="row mb-4">
            <div className="col-12 col-lg-8">
              <h3 className="font-bold mb-4">
                Collection, transport of body and storage for 48 hours
              </h3>
              <table class="table custom-table mb-0">
                <thead>
                  <tr>
                    <th scope="col">
                      <p className="mb-0">Transfers (Mon–Fri 8am to 4pm)</p>
                    </th>
                    <th scope="col" aria-label="prices"></th>
                  </tr>
                </thead>
                <tbody>
                  {tables.transfers.weekdays.map((item, i) => {
                    return (
                      <tr key={`table-packages-${i}`}>
                        <td>
                          <p className="mb-0">{item.description}</p>
                        </td>
                        <td>
                          <p className="mb-0">${item.price}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              <table class="table custom-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <p className="mb-0">
                        Transfers (Weekend, after hours & public holidays)
                      </p>
                    </th>
                    <th scope="col" aria-label="prices"></th>
                  </tr>
                </thead>
                <tbody>
                  {tables.transfers.weekends.map((item, i) => {
                    return (
                      <tr key={`table-packages-${i}`}>
                        <td>
                          <p className="mb-0">{item.description}</p>
                        </td>
                        <td>
                          <p className="mb-0">${item.price}</p>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          {/* Extras */}
          <div className="row mb-6">
            <div className="col-12 col-lg-8">
              <h3 className="font-bold mb-4">Optional Extras (if required)</h3>
              <table class="table custom-table">
                <thead>
                  <tr>
                    <th scope="col">
                      <p className="mb-0">Extras</p>
                    </th>
                    <th scope="col" aria-label="prices"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className="mb-0">Preparation of body for viewing</p>
                    </td>
                    <td>
                      {" "}
                      <p> $175 </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">Viewing of body</p>
                    </td>
                    <td>
                      <p>$110 per hour (mon-fri 8-4pm)</p>
                      <p className="mb-0">
                        $195 per hour (if after hours/weekends)
                      </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p className="mb-0">
                        Storage of body is free for 48 hours. Extra charges as
                        follows
                      </p>
                    </td>
                    <td>
                      <p className="mb-0">
                        $33 per day (charged only if more than 48 hours of
                        storage is required)
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              <p>
                We use the mortuary and crematorium at Smeaton Grange, NSW
                operated by Personal Services Australia & Independent Mortuary
                Services (PSA).
                <br />
                <br />
                Transportation of the body prior to cremation will be in a
                suitable vehicle owned by PSA. The body will be kept in a
                mortuary/holding room until cremation.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NSWInformation
